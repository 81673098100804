function openingHoursReload() {
    var localTime = new Date();
    if(statusReloadInHour !== localTime.getUTCHours()) {
        statusReloadInHour = localTime.getUTCHours();
        getOpeningHoursInfo(getServerTime(localTime, true));
    } else {
        getServerTime(localTime, false);
    }
    openingHoursTimeout = setTimeout(function () {
        openingHoursReload();
    }, 1000);

}

function getServerTime(localtime, force) {
    var serverDate = serverDateInit;
    var serverTimeString = serverTimeStringInit;
    try {
        serverTimeString = localtime.toLocaleTimeString(navigator.language, {
            timeZone: serverTimeZone, timeZoneName: 'short', hour: '2-digit', minute: '2-digit'
        });
        serverDate = new Date(localtime.toLocaleString("en-US", {
            timeZone: serverTimeZone
        }));
    } catch (err) {
        if(force) {
            try {
                $.ajax({
                    url: "/servertime.php",
                    type: 'get',
                    cache: false,
                    dataType: 'text',
                    async: false,
                    success: function (data) {
                        serverDate = new Date(data);
                    }
                });
                serverTimeString = serverDate.toLocaleTimeString(navigator.language, {
                    timeZoneName: 'short',
                    hour: '2-digit',
                    minute: '2-digit'
                }) + '&nbsp;' + serverTimeZoneAbrev;
            } catch (err) {

            }
        }
    }
    $(".time-container").html(serverTimeString);
    return serverDate;
}


function getOpeningHoursInfo(serverDate)
{
    var status = 1;
    try {
        status = getOpeningHoursStatus(serverDate);
    }
    catch(err) {
    }
    switch (status) {
        case 2:
        case 3:
        case 4:
            $(".opening-hour-status-icon .icon").removeClass('icon-check-circle').addClass('icon-ban');
            $(".opening-hour-status-text").html(openingHoursTextClosed);
            break;
        case 1:
        default:
            $(".opening-hour-status-icon .icon").removeClass('icon-ban').addClass('icon-check-circle');
            $(".opening-hour-status-text").html(openingHoursTextOpen);
            break;
    }
}

function getOpeningHoursStatus(serverDate)
{
    var year = serverDate.getFullYear();
    if(serverDate.getDay() === 0 || serverDate.getDay() === 6) {
        return 2;
    }

    //hours
    var hours = serverDate.getHours();
    if(hours >= openingHoursTo || hours < openingHoursFrom) {
        return 3;
    }

    //public holiday
    var holidayArray = [
        new Date(year,1-1,1),
        easterDate(year,-2),
        easterDate(year,1),
        new Date(year,5-1,1),
        new Date(year,5-1,8),
        new Date(year,7-1,5),
        new Date(year,7-1,6),
        new Date(year,9-1,28),
        new Date(year,10-1,28),
        new Date(year,11-1,17),
        new Date(year,12-1,24),
        new Date(year,12-1,25),
        new Date(year,12-1,26),
        new Date(year,12-1,27),
        new Date(year,12-1,28),
        new Date(year,12-1,29),
        new Date(year,12-1,30),
        new Date(year,12-1,31),
    ];

    var todayDate = serverDate.setHours(0,0,0,0);
    for(var i = 0; i < holidayArray.length; i++) {
        if(holidayArray[i].setHours(0,0,0,0) === todayDate) {
            return 4;
        }
    }
    return 1;
}

function easterDate (year, shift) {
    var a = year % 19;
    var b = Math.floor(year / 100);
    var c = year % 100;
    var d = Math.floor(b / 4);
    var e = b % 4;
    var f = Math.floor((b + 8) / 25);
    var g = Math.floor((b - f + 1) / 3);
    var h = (19 * a + b - d - g + 15) % 30;
    var i = Math.floor(c / 4);
    var k = c % 4;
    var l = (32 + 2 * e + 2 * i - h - k) % 7;
    var m = Math.floor((a + 11 * h + 22 * l) / 451);
    var n0 = (h + l + 7 * m + 114)
    var n = Math.floor(n0 / 31) - 1;
    var p = n0 % 31 + 1;
    var easterDate = new Date(year,n,p);

    var movedDate = new Date(easterDate);
    movedDate.setDate(easterDate.getDate() + shift);
    return movedDate;
}
